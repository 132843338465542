<template>
	<h3 ref="headline" v-html="headline"></h3>
</template>

<script>
	import gsap from "gsap";

  export default {
		name: "HeadlinePartial",
		props: {
			headline: {
				type: String,
				default: ""
			},
			playAnimation: {
				type: Boolean,
				default: true
			}
		},
		mounted() {
			if(this.playAnimation) {
				
				gsap.set(this.$refs.headline, {autoAlpha: 0});
				
				gsap.to( this.$refs.headline,{
					scrollTrigger: {
						trigger: this.$refs.headline,
						start: "top 70%",
						end: "bottom 20%",
						toggleActions: "play none none reverse",
					},
					autoAlpha: 1,
					duration: 0.5
				});
				
			}
		}
	}
</script>