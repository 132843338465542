<template>
	<div class="Y-2021">
		
		<Basic theme="light">
			
			<YearPartial ref="year" :year="2021" text-align="center"/>
			
			<TwoColumn :pad-col-left="true">
				
				<template v-slot:left>
					<HeadlinePartial ref="headline" :headline="$t('2021.headline')" />
					<BodyPartial ref="body" :body="$t('2021.body')" />
				</template>
				
				<template v-slot:right>
					<ImagePartial :play-animation="false" ref="image" image="2021_Ching.jpg" />
				</template>
				
			</TwoColumn>
		
		</Basic>
	
	</div>
</template>

<script>
import Basic from "@/components/layouts/Basic";
import YearPartial from "@/components/partials/YearPartial";
import TwoColumn from "@/components/sub-layouts/TwoColumn";
import ImagePartial from "@/components/partials/ImagePartial";
import HeadlinePartial from "@/components/partials/HeadlinePartial";
import BodyPartial from "@/components/partials/BodyPartial";
import {bgcolor} from "@/mixins/bgcolor";

export default {
	name: "Y-2021",
	mixins: [bgcolor],
	data() {
		return {
			bgColor: 'light'
		}
	},
	components: {BodyPartial, HeadlinePartial, ImagePartial, TwoColumn, YearPartial, Basic}
}
</script>