<template>
	<div class="Y-2008">
		<Basic theme="light">
			
			<YearPartial ref="year" :pad="true" :year="2008" text-align="center" />
			
			<TwoColumn :pad-col-right="true">
				
				<template v-slot:left>
					<ImagePartial :play-animation="false" ref="image" image="2008-ann.jpg" />
				</template>
				
				<template v-slot:right>
					<HeadlinePartial ref="headline" :headline="$t('2008.headline')" />
					<BodyPartial ref="body" :body="$t('2008.body')" />
					<PullQuotePartial ref="quote" :quote="$t('2008.pull-quote')" :author="$t('2008.author')" :fancy="false" />
					<AudioPartial  v-if="$root.$i18n.locale === 'en'" file-name="anne-joins-burgundy.mp3" :heading="$t('2008.caption')" />
				</template>
			
			</TwoColumn>
			
		</Basic>
	</div>
</template>

<script>
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import Basic from "@/components/layouts/Basic";
	import ImagePartial from "@/components/partials/ImagePartial";
	import YearPartial from "@/components/partials/YearPartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import PullQuotePartial from "@/components/partials/PullQuotePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	import AudioPartial from "@/components/partials/AudioPartial";
	
	export default {
		name: "Y-2008",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {
			AudioPartial,
			BodyPartial, PullQuotePartial, HeadlinePartial, YearPartial, ImagePartial, Basic, TwoColumn}
	}
</script>

<style lang="scss">
  .Y-2008 {
    @media screen and (max-width: $tablet) {
      .image-container {
        margin-bottom: $mobile-pad;
      }
    }
  }
</style>