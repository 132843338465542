<template>
	<div class="Y-2014">
		<Basic theme="dark">
			<YearPartial ref="year" :pad="true" :year="2014" text-align="center"/>
			<TwoColumn :pad-col-right="true" >
				
				<template v-slot:left>
					<Vimeo :play-animation="false" ref="video" :vimeo-code="$t('2014.video')" />
				</template>
				
				<template v-slot:right>
					<HeadlinePartial ref="headline" :headline="$t('2014.headline')" />
					<BodyPartial ref="body" :body="$t('2014.body')" />
				</template>
			
			</TwoColumn>
		</Basic>
	</div>
</template>

<script>
	import YearPartial from "@/components/partials/YearPartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import Vimeo from "@/components/partials/Vimeo";
	import Basic from "@/components/layouts/Basic";
	
	export default {
		name: "Y-2014",
		mixins: [bgcolor],
		
		components: {BodyPartial, HeadlinePartial, YearPartial, TwoColumn, Vimeo, Basic}
	}
</script>

<style lang="scss">
	.Y-2014 {
		@media screen and (max-width: $tablet) {
			.vimeo {
				margin-bottom: $mobile-pad;
			}
		}
	}
</style>