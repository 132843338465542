<template>
	<div class="Y-1993">
		<Basic theme="light">
			
			<YearPartial ref="year" :year="1993" text-align="center" />
			
			<TwoColumn :pad-col-left="true">
				<template v-slot:left>
					<HeadlinePartial ref="headline" :headline="$t('1993.top.headline')" />
					<BodyPartial ref="body" :body="$t('1993.top.body')" />
					<Link :url="$t('1993.top.link.url')" :link-text="$t('1993.top.link.link-text')" />
				</template>
				<template v-slot:right>
					<ImagePartial :play-animation="false" ref="image" image="1993_itsmorethanjustmoney.jpg" />
				</template>
			</TwoColumn>
			
			<SingleColumn text-align="left">
				<PullQuotePartial :play-animation="true" :quote="$t('1993.top.pull-quote')" :author="$t('1993.top.author')" />
			</SingleColumn>
			
			<TwoColumn :pad-col-right="true">
				<template v-slot:left>
					<ImagePartial image="1993_partnersglobal.jpg" />
				</template>
				<template v-slot:right>
					<HeadlinePartial  :play-animation="true" :headline="$t('1993.middle.headline')" />
					<BodyPartial :play-animation="true" :body="$t('1993.middle.body')" />
				</template>
			</TwoColumn>
			
			<TwoColumn :pad-col-left="true" :mobile-reorder="true">
				<template v-slot:left>
					<HeadlinePartial :play-animation="true" :headline="$t('1993.bottom.headline')" />
					<BodyPartial :play-animation="true" :body="$t('1993.bottom.body')" />
					<Link :url="$t('1993.bottom.link.url')" :link-text="$t('1993.bottom.link.link-text')" />
				</template>
				<template v-slot:right>
					<ImagePartial image="1993_outsidezebras.jpg" />
				</template>
			</TwoColumn>

		</Basic>
	</div>
</template>

<script>
	import Basic from "@/components/layouts/Basic";
	import YearPartial from "@/components/partials/YearPartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import ImagePartial from "@/components/partials/ImagePartial";
	import SingleColumn from "@/components/sub-layouts/SingleColumn";
	import PullQuotePartial from "@/components/partials/PullQuotePartial";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import Link from "@/components/partials/Link";
	import {bgcolor} from "@/mixins/bgcolor";
	
	export default {
		name: "Y-1993",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {
			Link,
			TwoColumn,
			PullQuotePartial, SingleColumn, ImagePartial, BodyPartial, HeadlinePartial, YearPartial, Basic}
	}
</script>

<style lang="scss">
	.Y-1993 {
		.pull-quote {
			margin-top: $desktop-pad;
			@media screen and (max-width: $tablet) {
				margin-top: $mobile-pad;
			}
		}
		.two-column {
			margin-top: calc(#{$desktop-pad} + 30px);
			@media screen and (max-width: $tablet) {
				margin-top: calc(#{$mobile-pad} + 15px);
        .image-container {
          margin-bottom: 30px;
        }
				&.mobile-flip .right-column {
					padding-bottom: 0;
				}
			}

		}
	}
</style>