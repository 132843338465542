<template>
	<p ref="link"><a :href="url" target="_blank" v-html="linkText"></a></p>
</template>

<script>
	import gsap from "gsap";
	
	export default {
		name: "Link",
		props: {
			url: {
				type: String,
				default: '#'
			},
			linkText: {
				type: String,
				default: 'Read More'
			},
			playAnimation: {
				type: Boolean,
				default: true
			}
		},
		mounted() {
			
			if(this.playAnimation) {
				
				gsap.set(this.$refs.link, {autoAlpha: 0});
				
				gsap.to( this.$refs.link,{
					scrollTrigger: {
						trigger: this.$refs.link,
						start: "top 70%",
						end: "bottom 50%",
						toggleActions: "play none none reverse",
					},
					autoAlpha: 1,
					duration: 0.5
				});
				
			}
			
		}
	}
</script>