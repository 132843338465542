<template>
	<div class="Y-2010">
		<Basic theme="dark">
			<YearPartial ref="year" :pad="true" :year="2010" text-align="center"/>
			<TwoColumn :pad-col-right="true" >

				<template v-slot:left>
          <ImagePartial image="2010_Anne-Mette_Dimitar-Ching.jpg" />
				</template>

				<template v-slot:right>
					<HeadlinePartial ref="headline" :headline="$t('2010.headline')" />
					<BodyPartial ref="body" :body="$t('2010.body')" />
				</template>

			</TwoColumn>
		</Basic>
	</div>
</template>

<script>
	import Basic from "@/components/layouts/Basic";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
  import ImagePartial from "@/components/partials/ImagePartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import YearPartial from "@/components/partials/YearPartial";
	import {bgcolor} from "@/mixins/bgcolor";

	export default {
		name: "Y-2010",
		mixins: [bgcolor],

		components: {YearPartial, BodyPartial, HeadlinePartial, ImagePartial, TwoColumn, Basic}
	}
</script>

<style lang="scss">
  .Y-2010 {
    @media screen and (max-width: $tablet) {
      .vimeo {
        margin-bottom: $mobile-pad;
      }
    }
  }
</style>
