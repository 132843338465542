<template>
	<section :class="['basic', theme, classProp]">
		<div class="inner-container">
			<slot></slot>
		</div>
	</section>
</template>

<script>
	export default {
		name: "Basic",
		props: {
			theme: {
				type: String,
				default: 'dark'
			},
      classProp: {
        type: String,
        default: ""
      }
		}
	}
</script>

<style lang="scss">
	.basic {
		padding: $desktop-pad 0;
		display: flex;
		align-items: center;
		justify-content: center;
    position: relative;
		@media screen and (max-width: $tablet) {
			padding: $mobile-pad 0;
		}
		&.dark {
			color: white;
			background-color: $deep-blue;
		}
		&.light {
			color: $deep-blue;
			background-color: $cream;
		}
	}
</style>