<template>
	<div class="Y-2009">
		
		<MultiSplitQuadrants
			top-image="2009-strength.jpg"
			bottom-image="2009_internationalexpansion.jpg"
		>
			
			<template v-slot:top>
				<YearPartial ref="year" :year="2009" />
				<HeadlinePartial ref="headline" :headline="$t('2009.top.headline')" />
				<BodyPartial ref="body" :body="$t('2009.top.body')" />
			</template>
			
			<template v-slot:bottom>
				<HeadlinePartial :play-animation="true" :headline="$t('2009.bottom.headline')" />
				<BodyPartial :play-animation="true" :body="$t('2009.bottom.body')" />
			</template>
		
		</MultiSplitQuadrants>
		
	</div>
</template>

<script>
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import YearPartial from "@/components/partials/YearPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	import MultiSplitQuadrants from "@/components/layouts/MultiSplitQuadrants";
	
	export default {
		name: "Y-2009",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {
			MultiSplitQuadrants, BodyPartial, HeadlinePartial,
			YearPartial}
	}
</script>

<style lang="scss">
	.Y-2009 {
		.bottom-right {
			justify-content: center;
			display: flex;
			flex-direction: column;
		}
		.image-container.full-height img {
			object-position: top right;
		}
	}
</style>