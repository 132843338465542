<template>
  <section class="multi-split-quadrants-no-top">
    <div class="bottom-left">
      <slot name="bottom"></slot>
    </div>
    <div class="bottom-right">
      <ImagePartial
        :image="bottomImage"
        :alt="bottomImageAlt"
        :full-height="true"
      />
    </div>
  </section>
</template>

<script>
import ImagePartial from "@/components/partials/ImagePartial";
export default {
  name: "MultiSplitQuadrants",
  components: { ImagePartial },
  props: {
    year: {
      type: Number,
      default: null,
    },
    topImage: {
      type: String,
      default: "",
    },
    topImageAlt: {
      type: String,
      default: "",
    },
    bottomImage: {
      type: String,
      default: "",
    },
    bottomImageAlt: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.multi-split-quadrants-no-top {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }
  .top-left,
  .bottom-left {
    padding: $desktop-pad;
    @media screen and (max-width: $tablet) {
      padding: $mobile-pad;
    }
  }
  .top-left,
  .top-right,
  .bottom-left,
  .bottom-right {
    flex-basis: 50%;
    min-height: 100vh;
    position: relative;
    @media screen and (max-width: $tablet) {
      flex-basis: 100%;
      min-height: auto;
    }
  }
  .top-right,
  .top-left {
    background-color: $deep-blue;
    color: white;
    min-height: 10vh;
  }
  .bottom-right,
  .bottom-left {
    background-color: $cream;
  }
  @media screen and (max-width: $tablet) {
    .bottom-right {
      order: 3;
    }
    .bottom-left {
      order: 4;
    }
  }
  .top-left {
    z-index: 1;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transform-origin: bottom right;
      background-color: $deep-blue;
      transform: skewY(-10deg);
    }
  }
  .bottom-right {
    z-index: 1;
    @media screen and (max-width: $tablet) {
      transform-origin: bottom right;
      padding-bottom: 0;
    }
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      transform-origin: top left;
      background-color: $cream;
      transform: skewY(-10deg);
      @media screen and (max-width: $tablet) {
        transform-origin: bottom right;
      }
    }
  }
  .image-container {
    flex-basis: 50%;
  }
}
</style>
