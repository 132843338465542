export default {
	
	state: {
		activeYear: 1990,
		years: [
			1990,
			1991,
			1993,
			1995,
			1996,
			1997,
			1998,
			2000,
			2001,
			2004,
			2005,
			2008,
			2009,
			2010,
			2011,
			2014,
			2017,
			2018,
			2019,
			2020,
			2021,
			2023
		],
		lastColor: 'dark',
		backgroundColor: 'dark',
		timelineActive: false
	},
	
	getters: {
		getYears(state) {
			return state.years;
		},
		getActiveYearIndex(state) {
			return state.years.indexOf(state.activeYear);
		},
		getBackgroundColor(state) {
			return state.backgroundColor;
		},
		getLastBackgroundColor(state) {
			return state.lastColor;
		}
	},
	
	actions: {
		resetColor({commit, state}) {
			commit("backgroundColor", state.lastColor);
		}
	},
	
	mutations: {
		activeYear(state, year) {
			state.activeYear = year;
		},
		timelineActive(state, bool) {
			state.timelineActive = bool;
		},
		backgroundColor(state, theme) {
			state.lastColor = state.backgroundColor;
			state.backgroundColor = theme;
		}
	}
	
}