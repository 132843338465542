<template>
  <div class="Y-2023">
    <FullImage
      image="2023_forest_dark.jpg"
      theme="dark"
      bg-color="#011e2c"
      vertical-align="top"
      max-width="650px"
      horizontal-align="left"
      startingPosition="top 30%"
    >
      <YearPartial ref="year" :year="2023" />
      <HeadlinePartial ref="headline" :headline="$t('2023.headline')" />
      <BodyPartial ref="body" :body="$t('2023.body')" />
    </FullImage>
  </div>
</template>

<script>
import FullImage from "@/components/layouts/FullImage";
import YearPartial from "@/components/partials/YearPartial";
import HeadlinePartial from "@/components/partials/HeadlinePartial";
import BodyPartial from "@/components/partials/BodyPartial";
import { bgcolor } from "@/mixins/bgcolor";
export default {
  name: "Y-2023",
  mixins: [bgcolor],

  components: { BodyPartial, HeadlinePartial, YearPartial, FullImage },
};
</script>

<style lang="scss">
.Y-2023 {
  .full-bleed-image {
    min-height: 80vh;
    background-position: bottom right;
    background-size: cover;
    @media screen and (max-width: $tablet) {
      min-height: 100vh;
      background-position: bottom right;
      background-size: cover;
      padding-bottom: 110px;
      padding-top: 0;
    }
  }
}
</style>
