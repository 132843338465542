<template>
	<div ref="audio" class="podcast">
		<audio preload="metadata" controls>
			<source :src="require(`@/assets/audio/${fileName}`)" type="audio/mpeg">
		</audio>
		<h3 v-if="heading" v-html="heading"></h3>
	</div>
</template>

<script>
import gsap from "gsap";

export default {
	name: "AudioPartial",
	props: {
		fileName: {
			type: String,
			default: ''
		},
		heading: {
			type: String,
			default: ''
		},
		playAnimation: {
			type: Boolean,
			default: true
		}
	},
	mounted() {
		if(this.playAnimation) {
			
			gsap.set(this.$refs.audio, {autoAlpha: 0, y: 50});
			
			gsap.to( this.$refs.audio,{
				scrollTrigger: {
					trigger: this.$refs.audio,
					start: "top 80%",
					end: "bottom 20%",
					toggleActions: "play none none reverse",
				},
				autoAlpha: 1,
				duration: 0.5,
				y: 0
			});
		}
	}
}
</script>

<style lang="scss">
	.podcast {
		margin: 20px 0 40px 0;
		audio {
			width: 100%;
		}
		h3 {
			font-size: 14px;
			font-style: italic;
			text-transform: none;
			margin-top: 30px;
			@media screen and (max-width: $tablet) {
				font-size: 13px;
			}
		}
	}
</style>