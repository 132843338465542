<template>
	<div>
		<section ref="Intro" id="Intro" class="intro-top">
			<FrenchToggle />
			
			<div class="inner-content">
				
				<div ref="logo" class="logo">
					<img :src='require("@/assets/images/Burgundy_LOGOS-" + $root.$i18n.locale + ".svg")' alt="Burgundy Logo" />
				</div>
				
				<div class="intro-heading">
					<h1 ref="headline" v-html="$t('intro.top.headline')"></h1>
					<div ref="goldLine" class="gold-line-bottom-intro"></div>
				</div>
				
				<div ref="content" class="content">
					<p v-html="$t('intro.top.body')"></p>
				</div>
				
				<div ref="scrollContainer" class="scroll-cta-container">
					<p class="scroll-cta">{{ $t('intro.top.cta') }}</p>
					
					<div class="scroll-image-container">
						<img ref="arrow" class="scroll-arrow" :src='require("@/assets/images/modern-arrow.svg")' alt="" />
					</div>
					
				</div>
			
			</div>
		</section>
		
		<Basic theme="dark" class-prop="intro-slant">
			
			<TwoColumn ref="quoteContainer" class-prop="intro-quote" :pad-col-right="true" :pad-col-left="true">
				<template slot="left">
					<ImagePartial image="intro-tony-richard.jpg" />
				</template>
				<template slot="right">
					<PullQuotePartial :quote="$t('intro.bottom.pull-quote.body')" :author="$t('intro.bottom.pull-quote.author')" />
				</template>
			</TwoColumn>
			
			<SingleColumn ref="ctaContainer" text-align="left" max-width="900px">
				<BodyPartial ref="cta" :body="$t('intro.bottom.cta')" class-name="intro-cta" />
			</SingleColumn>

		</Basic>
		
	</div>
</template>

<script>

	import { gsap } from 'gsap';
	
	import FrenchToggle from "@/components/partials/FrenchToggle"
	import SingleColumn from "@/components/sub-layouts/SingleColumn"
	import Basic from "@/components/layouts/Basic"
	import BodyPartial from "@/components/partials/BodyPartial"
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import PullQuotePartial from "@/components/partials/PullQuotePartial";
	import ImagePartial from "@/components/partials/ImagePartial";
	
	export default {
		name: "Intro",
		components: {ImagePartial, PullQuotePartial, TwoColumn, BodyPartial, FrenchToggle, Basic, SingleColumn},
		mounted() {
			
				this.introAnimation();
				this.ctaAnimation();
			
		},
		methods: {
			introAnimation() {
				
				let tl = gsap.timeline({
					scrollTrigger: { // create a scroll trigger for each year container
						trigger: this.$refs.Intro, // use the main year container as the trigger
						start: "top 5%", // we want to start the animations a little lower that the top
						end: "bottom top", // we end it at the bottom of the section
						toggleActions: "play none none none",
						onEnter: () => {
							this.$store.commit("timelineActive", false);
							this.$store.commit("activeYear", 1990);
						},
						onEnterBack: () => {
							this.$store.commit("timelineActive", false);
							this.$store.commit("activeYear", 1990);
						}
					},
					
				});
				
				tl.add(gsap.timeline()
									 .from(this.$refs.logo, {autoAlpha: 0, duration: 1, delay: 0.6})
									 .from(this.$refs.logo, {scale: 2, y: 200, duration: 1})
									 .from(this.$refs.headline, {autoAlpha: 0, duration: 0.5})
									 .from(this.$refs.goldLine, {scaleX: 0, duration: 0.5})
									 .from(this.$refs.content, {autoAlpha: 0, duration: 0.5})
									 .from(this.$refs.scrollContainer, {autoAlpha: 0, duration: 0.5})
									 .from(this.$refs.arrow, {autoAlpha: 0, y: -20, duration: 0.5})
				);
			},
			ctaAnimation() {
				
				gsap.from(this.$refs.cta.$el, {
					scrollTrigger: {
						trigger: this.$refs.ctaContainer.$el,
						start: "top 90%",
						end: "bottom top",
						toggleActions: "play none none reverse",
						toggleClass: {targets: this.$refs.cta.$el, className: "active"}
					},
					autoAlpha: 0
				});
				
			}
		}
	}

</script>

<style lang="scss">

	.intro-heading {
		@media screen and (max-width: $mobile) {
			margin-bottom: 20px;
		}
	}

	.intro-top {
		text-align: center;
		color: white;
		background-color: $deep-blue;
		display: flex;
		justify-content: center;
		align-items: center;
		background-image: url("~@/assets/images/intro.jpg");
		@extend .cover-bg;
		
		.logo {
			padding: 70px 50px 50px 50px;
			@media screen and (max-width: $mobile) {
				padding-bottom: 25px;
			}
			img {
				width: 250px;
			}
		}
		.content {
			max-width: 800px;
      padding: 20px;
			p {
				font-size: 2.4rem;
				line-height: 1.6;
				margin-bottom: 10px;
				padding: 0 20px;
				@media screen and (max-width: $mobile) {
					font-size: 2rem;
					padding: 0;
					
				}
			}
		}
		.inner-content {
			min-height: 100vh;
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-direction: column;
			background: linear-gradient(to bottom, rgba($deep-blue, 1), rgba($deep-blue, 0.8));
			width: 100%;
		}
		h1{
      font-size: 7.4rem;
      line-height: 1;
			text-transform: uppercase;
      span {
        font-family: $gotham;
        font-weight: 400;
        font-size: 4.8rem;
      }
			span.lower {
				text-transform: lowercase;
			}
		}
		.gold-line-bottom-intro {
			width: 100%;
			height: 3px;
			background-color: $gold;
			display: block;
			margin-top: 5px;
		}
		h3 {
			margin-bottom: 30px;
		}
	}
	
	.fr {
		h1{
			font-size: 6.4rem;
			line-height: 1;
			text-transform: uppercase;
			span {
				font-family: $gotham;
				font-weight: 400;
				font-size: 4.4rem;
			}
			sup.upper {
				text-transform: lowercase;
			}
			@media screen and (max-width: $tablet) {
				font-size: 4rem;
				span {
					font-size: 2.8rem;
				}
			}
		}
		.intro-top {
			.content {
				p {
					font-size: 2rem;
				}
			}
		}
	}
	
	.intro-quote {
		margin-top: $desktop-pad;
		margin-bottom: $desktop-pad;
		@media screen and (max-width: $tablet) {
			margin-top: $mobile-pad;
			margin-bottom: $mobile-pad;
		}
		.left-column {
			padding-right: 0;
			@media screen and (max-width: $tablet) {
				margin-bottom: $mobile-pad;
				padding-right: $mobile-pad;
			}
		}
	}
	
	.intro-body {
		padding: 80px 0 0 0;
    @media screen and (max-width: $mobile) {
      padding: 40px 0 0 0;
    }
	}
  .scroll-cta-container {
    padding-bottom: 60px;
    .scroll-cta {
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    .scroll-arrow {
      transform: rotate(180deg);
      margin-bottom: 30px;
    }

  }
  .intro-slant {
    padding: 80px 0 40px 0;
    .inner-container {
      z-index: 2;
    }
    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left:0;
      transform-origin: bottom left;
      width: 100%;
      height: 100%;
      background-color: $deep-blue;
      transform: skewY(-6deg);
    }
  }
  .intro-cta {
    text-align: left;
    font-size: 2.4rem;
    line-height: 1.8;
    font-family: $gotham;
		margin: calc(#{$desktop-pad} * 2) 0;
		span {
			position: relative;
			&:after {
				transition: width .8s linear;
				position: absolute;
				height: 2px;
				background-color: $gold;
				width: 0;
				left: 0;
				bottom: -10px;
				content: " ";
			}
		}
    @media screen and (max-width: $tablet) {
      font-size: 2.2rem;
			margin: $desktop-pad 0;
    }
		&.active {
			span {
				&:after {
					width: 100%;
				}
			}
		}
  }
  .intro-bottom {
    padding-top: 0;
    min-height: auto;
  }
	.scroll-image-container {
		animation: bounce 1.5s forwards infinite;
	}
	
	@keyframes bounce {
		0%, 100% {
			transform: translateY(-3px);
		}
		50% {
			transform: translateY(3px);
		}
	}
	
	
</style>