<template>
  <div id="app" :class="currentLang" :style="{ backgroundColor: bgColor }">
    <Navigation />

    <main>
      <Intro />

      <component
        v-for="year in $store.getters.getYears"
        :key="year"
        :is="`Y${year}`"
        :ref="year"
      />

      <Outro />
    </main>
  </div>
</template>

<script>
import Intro from "@/components/Intro";
import Navigation from "@/components/Navigation";
import Y1990 from "@/components/years/Y-1990";
import Y1995 from "@/components/years/Y-1995";
import Y1996 from "@/components/years/Y-1996";
import Y1997 from "@/components/years/Y-1997";
import Y1998 from "@/components/years/Y-1998";
import Y2000 from "@/components/years/Y-2000";
import Y2001 from "@/components/years/Y-2001";
import Y2004 from "@/components/years/Y-2004";
import Y2005 from "@/components/years/Y-2005";
import Y2008 from "@/components/years/Y-2008";
import Y2009 from "@/components/years/Y-2009";
import Y2010 from "@/components/years/Y-2010";
import Y1991 from "@/components/years/Y-1991";
import Y1993 from "@/components/years/Y-1993";
import Y2011 from "@/components/years/Y-2011";
import Y2014 from "@/components/years/Y-2014";
import Y2017 from "@/components/years/Y-2017";
import Y2018 from "@/components/years/Y-2018";
import Y2019 from "@/components/years/Y-2019";
import Y2020 from "@/components/years/Y-2020";
import Y2021 from "@/components/years/Y-2021";
import Y2023 from "@/components/years/Y-2023";
import Outro from "@/components/Outro";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "App",
  components: {
    Outro,
    Y2023,
    Y2021,
    Y2020,
    Y2019,
    Y2018,
    Y2017,
    Y2014,
    Y2011,
    Y1993,
    Y1991,
    Y2010,
    Y2009,
    Y2008,
    Y2005,
    Y2004,
    Y2001,
    Y2000,
    Y1998,
    Y1997,
    Y1996,
    Y1995,
    Y1990,
    Navigation,
    Intro,
  },
  data() {
    return {
      dark: "#0f111c",
      light: "#FEFAF3",
    };
  },
  computed: {
    bgColor() {
      return this.$store.getters.getBackgroundColor === "dark"
        ? this.dark
        : this.light;
    },
    currentLang() {
      return this.$t("changeToLang") === "en" ? "fr" : "en";
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleFirstTab);

    // prevents swipe navigation on safari
    if (window.safari) {
      history.pushState(null, null, location.href);
      window.onpopstate = (_event) => {
        history.go(1);
      };
    }

    this.$store.getters.getYears.forEach((year) => {
      let className = `.Y-${year}`;

      let _tl = gsap
        .timeline({
          scrollTrigger: {
            // create a scroll trigger for each year container
            trigger: className, // use the main year container as the trigger
            start: "top 50%", // we want to start the animations a little lower that the top
            end: "70% top", // we end it at the bottom of the section
            toggleActions: "play none none reverse",
            onEnter: () => {
              this.$store.commit("timelineActive", true); // when each scroll area is entered, we tell Vuex that the nav should be active
              this.$store.commit("activeYear", year); // and we also set the current year so that the nav updates as we scroll
            },
            onEnterBack: () => {
              this.$store.commit("timelineActive", true); // when each scroll area is re-entered, we tell Vuex that the nav should be active
              this.$store.commit("activeYear", year); // and we also set the current year so that the nav updates as we scroll
            },
          },
        })
        .from(className, {
          y: 60,
          autoAlpha: 0,
          duration: 1,
          ease: "power4.out",
          onComplete: () => {
            // update color
            let theme = this.$refs[year][0].bgColor;
            this.$store.commit("backgroundColor", theme);
          },
        });
    });
  },
  methods: {
    handleFirstTab(e) {
      if (e.keyCode === 9) {
        document.body.classList.add("user-is-tabbing");
        window.removeEventListener("keydown", this.handleFirstTab);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  transition: background-color 1s linear;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  overscroll-behavior-x: none;
}

html {
  font-size: 62.5%;
  @media screen and (max-width: $mobile) {
    font-size: 50%;
  }
}

body {
  font-size: 1.8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: $domaine;
  font-weight: $bold;
  font-size: 5.7rem;
}

h2 {
  font-family: $ivy;
  font-weight: $extra-bold;
  line-height: 1;
  font-size: 10rem;
  display: inline-block;
}

h3 {
  font-family: $gotham;
  font-weight: $regular;
  font-size: 2.1rem;
  line-height: 1.7;
  margin-bottom: 35px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

p {
  font-family: $gotham;
  font-size: 1.8rem;
  font-weight: $regular;
  line-height: 2;
  margin-bottom: 35px;
  b,
  strong {
    font-weight: 450;
  }
  a {
    font-size: inherit;
    text-decoration: underline;
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
}

section {
  min-height: 100vh;
  width: calc(100% - 100px);
  margin-left: 100px;
  @media screen and (max-width: $mobile) {
    width: 100vw;
    margin-left: 0;
  }
}
</style>
