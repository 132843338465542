<template>
	<div class="Y-2005">
		<Basic theme="dark">
			
			<YearPartial ref="year" :year="2005" text-align="center" />
			
			<TwoColumn :pad-col-left="true" :mobile-reorder="true">
				
				<template v-slot:left>
					<HeadlinePartial ref="headline" :headline="$t('2005.headline')" />
					<BodyPartial ref="body" :body="$t('2005.body')" />
					<PullQuotePartial ref="quote" :quote="$t('2005.pull-quote')" :fancy="false" :author="$t('2005.author')" />
				</template>
				
				<template v-slot:right>
					<ImagePartial :play-animation="true" ref="image" image="2005_Rob.jpg" />
				</template>
				
			</TwoColumn>
			
		</Basic>
	</div>
</template>

<script>
	import Basic from "@/components/layouts/Basic";
	import YearPartial from "@/components/partials/YearPartial";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import PullQuotePartial from "@/components/partials/PullQuotePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import ImagePartial from "@/components/partials/ImagePartial";
	import {bgcolor} from "@/mixins/bgcolor";
	export default {
		name: "Y-2005",
		mixins: [bgcolor],
		
		components: {ImagePartial, BodyPartial, PullQuotePartial, HeadlinePartial, TwoColumn, YearPartial, Basic}
	}
</script>