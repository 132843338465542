<template>
	<div class="Y-2017">
		<Basic theme="light">
			<YearPartial ref="year" :year="2017" text-align="center" />
			
			<TwoColumn :v-align-right="`center`" :pad-col-right="true">
				
				<template v-slot:right>
					<HeadlinePartial ref="headline" :headline="$t('2017.headline')" />
					<BodyPartial ref="body" :body="$t('2017.body')" />
				</template>
				
				<template v-slot:left>
					<ImagePartial :play-animation="false" ref="image" image="2017_Japan-Research-Trip.jpg" />
				</template>
				
			</TwoColumn>
			
		</Basic>
	</div>
</template>

<script>
	import Basic from "@/components/layouts/Basic";
	import YearPartial from "@/components/partials/YearPartial";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import ImagePartial from "@/components/partials/ImagePartial";
	import {bgcolor} from "@/mixins/bgcolor";
	export default {
		name: "Y-2017",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {ImagePartial, BodyPartial, HeadlinePartial, TwoColumn, YearPartial, Basic}
	}
</script>