<template>
	<div class="Y-1998">
		
		<MultiSplitSlantHalf
			top-theme="light"
			bottom-theme="dark"
			slant-direction="right"
			slant-half="top"
		>
			
			<template v-slot:top>
				
				<YearPartial ref="year" :year="1998" :pad="true" />
				
				<TwoColumn :pad-col-left="true">
					
					<template v-slot:left>
						<HeadlinePartial ref="headline" :headline="$t('1998.top.headline')" />
						<BodyPartial ref="body" :body="$t('1998.top.body')" />
						<Link :play-animation="false" ref="link" :link-text="$t('1998.top.link.link-text')" :url="$t('1998.top.link.url')" />
					</template>
					
					<template v-slot:right>
						<PullQuotePartial ref="quote" :quote="$t('1998.top.pull-quote')" :author="$t('1998.top.author')" />
					</template>
					
				</TwoColumn>
				
			</template>
			
			<template v-slot:bottom>
				
				<TwoColumn :pad-col-left="true">
					
					<template v-slot:left>
						<HeadlinePartial  :play-animation="true" :headline="$t('1998.bottom.headline')" />
						<BodyPartial :play-animation="true" :body="$t('1998.bottom.body')" />
					</template>
					
					<template v-slot:right>
						<Vimeo :vimeo-code="$t('1998.bottom.video')" />
					</template>
					
				</TwoColumn>
				
			</template>
		
		</MultiSplitSlantHalf>
		
	</div>
</template>

<script>
	import MultiSplitSlantHalf from "@/components/layouts/MultiSplitSlantHalf";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import PullQuotePartial from "@/components/partials/PullQuotePartial";
	import YearPartial from "@/components/partials/YearPartial";
	import Link from "@/components/partials/Link";
	import Vimeo from "@/components/partials/Vimeo";
	import {bgcolor} from "@/mixins/bgcolor";
	
	export default {
		name: "Y-1998",
		mixins: [bgcolor],
		
		components: {
			Vimeo, Link, YearPartial, PullQuotePartial, BodyPartial, HeadlinePartial, TwoColumn, MultiSplitSlantHalf}
	}
</script>

<style lang="scss">
  .Y-1998 {
    .bottom-slant-split {
			padding-top: 50px;
			@media screen and (max-width: $tablet) {
				padding-top: 100px;
			}
			.two-column {
        .left-column, .right-column {
          padding-top: 150px;
          padding-bottom: 50px;
          @media screen and (max-width: $tablet) {
            padding-top: 0;
            padding-bottom: $mobile-pad;
          }
        }
      }
    }
    .top-slant-split {
      .right-column {
        @media screen and (max-width: $tablet) {
          padding: 0 30px;
        }
      }
    }
  }
</style>