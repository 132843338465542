<template>
	<div class="Y-2011">
		<Basic theme="light">
			<TwoColumn :pad-col-left="true" :v-align-left="`center`">

				<template v-slot:left>
					<YearPartial ref="year" :year="2011" />
					<HeadlinePartial ref="headline" :headline="$t('2011.headline')" />
					<BodyPartial ref="body" :body="$t('2011.body')" />
				</template>

				<template v-slot:right>
					<ImagePartial image="2018_deepdivechina.jpg" :full-height="true" />
				</template>

			</TwoColumn>
		</Basic>
	</div>
</template>

<script>
	import YearPartial from "@/components/partials/YearPartial";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import ImagePartial from "@/components/partials/ImagePartial";
	import Basic from "@/components/layouts/Basic";
	import {bgcolor} from "@/mixins/bgcolor";
	export default {
		name: "Y-2011",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {Basic, ImagePartial, BodyPartial, HeadlinePartial, TwoColumn, YearPartial}
	}
</script>

<style lang="scss">
	.Y-2011 {
		.basic {
			padding: 0;
			.two-column {
				.left-column {
					@media screen and (min-width: $tablet) {
						padding-bottom: $desktop-pad;
					}
				}
			}
      @media screen and (max-width: $tablet) {
        padding: $mobile-pad 0 0 0;
        .image-container {
          margin-top: $mobile-pad;
        }
      }
		}
	}
</style>