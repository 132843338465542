<template>
	<section :class="['full-bleed-image', theme]" :style="{backgroundColor: bgColor}">
		
		<div class="content" :class="[theme, verticalAlign, horizontalAlign]">
			<div class="inner-content" :style="{maxWidth}">
				<slot></slot>
			</div>
		</div>
		
		<div ref="bg" class="bg-image">
			<img :src="require(`@/assets/images/${this.image}`)" alt="" />
		</div>
		
	</section>
</template>

<script>
	import gsap from "gsap";
	
	export default {
		name: "FullImage",
		props: {
			image: {
				type: String,
				default: '1997.jpg'
			},
			theme: {
				type: String,
				default: 'light'
			},
			verticalAlign: {
				type: String,
				default: 'top'
			},
			horizontalAlign: {
				type: String,
				default: 'left'
			},
			maxWidth: {
				type: String,
				default: '750px'
			},
			bgColor: {
				type: String,
				default: "#000000"
			},
			startingPosition: {
				type: String,
                default: "top 5%"
			}
		},
		mounted() {
			
			gsap.set(this.$refs.bg, {autoAlpha: 0});
			
			gsap.to( this.$refs.bg,{
				scrollTrigger: {
					trigger: this.$refs.bg,
					start: this.startingPosition,
					end: "bottom 5%",
					toggleActions: "play none none reverse",
				},
				autoAlpha: 1,
				duration: 1.3
			});
			
		}
	}
</script>

<style lang="scss">

	.full-bleed-image {
		min-height: 120vh;
		background-repeat: no-repeat;
		background-size: 100%;
		display: flex;
		background-position: bottom right;
		position: relative;
		.bg-image {
			position: absolute;
			height: 100%;
			width: 100%;
			overflow: hidden;
			z-index: 0;
			max-width: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: bottom center;
			}
		}
		
		&.light {
			background-color: $cream;
		}
		&.dark {
			background-color: $deep-blue;
		}

		.content {
			min-height: 100vh;
			display: flex;
			width: 100%;
			padding: $desktop-pad;
			z-index: 1;
			@media screen and (max-width: $tablet) {
				padding: $mobile-pad;
			}
			&.top {
				align-items: flex-start;
			}
			&.center {
				align-items: center;
        @media screen and (max-width: $tablet) {
          align-items: flex-start;
        }
			}
			&.bottom {
				align-items: flex-end;
			}
			&.right {
				justify-content: flex-end;
			}
			&.left {
				justify-content: flex-start;
			}
			&.light {
				color: $deep-blue
			}
			&.dark {
				color: white;
			}
		}
		.inner-content {
			h2 {
				display: inline-block;
			}
		}
	}

</style>