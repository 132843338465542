<template>
	<div class="Y-1996">
		
		<Basic theme="dark">
			
			<YearPartial ref="year" :year="1996" :pad="true" text-align="center"/>
			
			<TwoColumn :pad-col-left="true">
				
				
				<template v-slot:right>
					<HeadlinePartial ref="headline" :headline="$t('1996.headline')" />
					<BodyPartial ref="body" :body="$t('1996.body')" />
					<PullQuotePartial ref="quote" :quote="$t('1996.pull-quote')" />
				</template>
				<template v-slot:left>
					<ImagePartial :scale="1.2" image="1996.jpg" />
				</template>
			</TwoColumn>
			
		</Basic>
		
	</div>
</template>

<script>
	import Basic from "@/components/layouts/Basic";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import ImagePartial from "@/components/partials/ImagePartial";
	import PullQuotePartial from "@/components/partials/PullQuotePartial";
	import YearPartial from "@/components/partials/YearPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	
	export default {
		name: "Y-1996",
		mixins: [bgcolor],
		components: {
			YearPartial,
			PullQuotePartial,
			ImagePartial,
			BodyPartial,
			HeadlinePartial,
			TwoColumn,
			Basic
		}
	}
</script>

<style lang="scss">
  .Y-1996 {
    @media screen and (max-width: $tablet) {
      .basic {
        padding-bottom:0;
      }
			.image-container {
				margin-top: $mobile-pad;
			}
    }
  }
</style>