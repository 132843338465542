<template>
	<div class="Y-2000">
		<Basic theme="light">
			<YearPartial ref="year" :year="2000" :pad="true" text-align="center"/>
			
			<TwoColumn  :pad-col-right="true" class-prop="top-column">
				<template v-slot:left>
					<Vimeo :play-animation="false" ref="video" :vimeo-code="$t('2000.top.video')" />
				</template>
				<template v-slot:right>
					<HeadlinePartial ref="headline" :headline="$t('2000.top.headline')" />
					<BodyPartial ref="body" :body="$t('2000.top.body')" />
				</template>
			</TwoColumn>
			
			<TwoColumn :pad-col-left="true" :v-align-left="`center`" :mobile-reorder="true" class-prop="bottom-column">
				<template v-slot:left>
					<HeadlinePartial :play-animation="true" :headline="$t('2000.bottom.headline')" />
					<BodyPartial :play-animation="true" :body="$t('2000.bottom.body')" />
				</template>
				<template v-slot:right>
					<ImagePartial image="2000-courage.jpg" />
				</template>
			</TwoColumn>
		</Basic>
	</div>
</template>

<script>
	import Basic from "@/components/layouts/Basic";
	import YearPartial from "@/components/partials/YearPartial";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import Vimeo from "@/components/partials/Vimeo";
	import {bgcolor} from "@/mixins/bgcolor";
	import ImagePartial from "@/components/partials/ImagePartial";
	
	export default {
		name: "Y-2000",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {ImagePartial, Vimeo, BodyPartial, HeadlinePartial, TwoColumn, YearPartial, Basic}
	}
</script>

<style lang="scss">
	.Y-2000 {
		.top-column {
			margin-bottom: 140px;
			@media screen and (max-width: $tablet) {
				margin-bottom: $mobile-pad;
        .image-container, .vimeo {
          margin-bottom: 30px;
        }
			}
		}
		.bottom-column {
			.image-container {
				@media screen and (max-width: $tablet) {
					margin-bottom: $mobile-pad;
				}
			}
		}
	}
</style>