<template>
	<p v-html="body" ref="body" :class="className"></p>
</template>

<script>
	import gsap from 'gsap';
	import { animations } from "@/mixins/animation"

	export default {
		name: "BodyPartial",
		mixins: [animations],
		props: {
			body: {
				type: String,
				default: ''
			},
			className: {
				type: String,
				default: ''
			},
			playAnimation: {
				type: Boolean,
				default: true
			}
		},
		mounted() {
			if(this.playAnimation) {
				
				gsap.set(this.$refs.body, {autoAlpha: 0, y: 100});
				
				gsap.to( this.$refs.body,{
					scrollTrigger: {
						trigger: this.$refs.body,
						start: "top 80%",
						end: "bottom 20%",
						toggleActions: "play none none reverse",
					},
					autoAlpha: 1,
					duration: 0.5,
					y: 0
				});
				
			}
		},
	}
</script>