<template>
	<div :class="['two-column', reorder ? 'flip' : '', mobileReorder ? 'mobile-flip' : '', classProp]">
		<div :class="['left-column', padColLeft ? 'pad' : '', `valign-${vAlignLeft}`]">
			<slot name="left"></slot>
		</div>
		<div :class="['right-column', padColRight ? 'pad' : '', `valign-${vAlignRight}`]">
			<slot name="right"></slot>
		</div>
	</div>
</template>

<script>

export default {
	name: "TwoColumn",
	props: {
		reorder: {
			type: Boolean,
			default: false
		},
    mobileReorder: {
      type: Boolean,
      default: false
    },
		padColLeft: {
			type: Boolean,
			default: false
		},
		vAlignLeft: {
			type: String,
			default: 'start'
		},
		padColRight: {
			type: Boolean,
			default: false
		},
		vAlignRight: {
			type: String,
			default: 'start'
		},
		classProp: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss">
.two-column {
	display: flex;
	@media screen and (max-width: $tablet) {
		flex-direction: column;
	}
	.pad {
		padding: 0 $desktop-pad;
		@media screen and (max-width: $tablet) {
			padding: 0 $mobile-pad;
		}
	}
	.valign-center {
		justify-content: center;
	}
	.valign-start {
		justify-content: flex-start;
	}
	.valign-end {
		justify-content: flex-end;
	}
	.left-column, .right-column {
		flex-basis: 50%;
		display: flex;
		flex-direction: column;
	}
	&.flip {
		.right-column {
			order: 1;
		}
		.left-column {
			order: 2;
		}
	}
  @media screen and (max-width: $tablet) {
    &.mobile-flip {
      .right-column {
        order: 1;
				padding-bottom: $mobile-pad;
      }
      .left-column {
        order: 2;
      }
    }
  }
}
</style>