<template>
	<div ref="video" class="vimeo">
		<div class="responsive-video">
			<iframe :src="`https://player.vimeo.com/video/${vimeoCode}?byline=0&portrait=0`" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
		</div>
	</div>
</template>

<script>
	import gsap from "gsap";
	
	export default {
		name: "Vimeo",
		props: {
			vimeoCode: {
				type: String,
				default: ''
			},
			playAnimation: {
				type: Boolean,
				default: true
			}
		},
		mounted() {
			
			if(this.playAnimation) {
				
				gsap.set(this.$refs.video, {autoAlpha: 0});
				
				gsap.to( this.$refs.video,{
					scrollTrigger: {
						trigger: this.$refs.video,
						start: "top 80%",
						end: "bottom 50%",
						toggleActions: "restart none none reverse",
					},
					autoAlpha: 1,
					duration: 0.5
				});
				
			}
			
		}
	}
</script>

<style lang="scss">
	.vimeo {
		width: 100%;
		.responsive-video {
			padding: 57% 0 0 0;
			position: relative;
		}
	}
</style>