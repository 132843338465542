<template>
  <div class="Y-2018">
    <Basic theme="light">
      <YearPartial ref="year" :year="2018" text-align="center" />
      <MultiSplitQuadrantsNoTop bottom-image="2018-china.jpg">
        <template v-slot:bottom>
          <HeadlinePartial
            :play-animation="true"
            :headline="$t('2018.top.headline')"
          />
          <BodyPartial :play-animation="true" :body="$t('2018.top.body')" />
          <Vimeo :vimeo-code="$t('2018.bottom.video')" />
        </template>
      </MultiSplitQuadrantsNoTop>
    </Basic>
  </div>
</template>

<script>
import YearPartial from "@/components/partials/YearPartial";
import HeadlinePartial from "@/components/partials/HeadlinePartial";
import BodyPartial from "@/components/partials/BodyPartial";
import { bgcolor } from "@/mixins/bgcolor";
import Vimeo from "@/components/partials/Vimeo";
import MultiSplitQuadrantsNoTop from "../layouts/MultiSplitQuadrantsNoTop.vue";
export default {
  name: "Y-2018",
  mixins: [bgcolor],
  data() {
    return {
      bgColor: "light",
    };
  },
  components: {
    Vimeo,
    BodyPartial,
    HeadlinePartial,
    YearPartial,
    MultiSplitQuadrantsNoTop,
  },
};
</script>

<style lang="scss">
.Y-2018 {
  .top-right {
    img {
      object-position: bottom center;
    }
  }
  .bottom-right {
    @media screen and (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      .vimeo {
        order: 2;
        margin-bottom: $mobile-pad;
      }
      h3 {
        order: 1;
      }
      p {
        order: 3;
      }
    }
  }
}
</style>
