<template>
	<div :class="['single-column', classProp]" :style="{textAlign}">
		<div class="inner-content" :style="{maxWidth}">
			<div class="content">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SingleColumn",
		props: {
			textAlign: {
				type: String,
				default: 'center'
			},
			maxWidth: {
				type: String,
				default: '100%'
			},
      classProp: {
        type: String,
        default: ""
      }
		}
	}
</script>

<style lang="scss">
	.single-column {
		padding: 0 $desktop-pad;
    .inner-content {
      margin: 0 auto;
    }
		@media screen and (max-width: $tablet) {
			padding: 0 $mobile-pad;
		}
	}
</style>