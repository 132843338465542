<template>
	<div ref="container" :class="['pull-quote-partial', mobilePadding ? 'mobile-pad' : '']">
		
		<div class="pull-quote-container">
			<div ref="gold" class="gold-line-left-animate"></div>
			<p ref="quote" :class="['pull-quote', fancy ? 'fancy' : '']" v-html="htmlQuote"></p>
		</div>
		
		<p ref="author" v-show="author" class="author">– {{ author }}</p>
		
	</div>
</template>

<script>
	import gsap from "gsap";

  export default {
		name: "PullQuotePartial",
		props: {
			quote: {
				type: String,
				default: ''
			},
			author: {
				type: String,
				default: ''
			},
			fancy: {
				type: Boolean,
				default: true
			},
			playAnimation: {
				type: Boolean,
				default: true
			},
			mobilePadding: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			htmlQuote() {
				if(this.$root.$i18n.locale === 'fr') {
					return `&laquo;&nbsp;${this.quote}&nbsp;&raquo;`
				} else {
					return `&ldquo;${this.quote}&rdquo;`
				}
			}
		},
		mounted() {
			
			gsap.set(this.$refs.container, {y: 20})
			
			gsap.to( this.$refs.container,{
				scrollTrigger: {
					trigger: this.$refs.container,
					start: "top 70%",
					end: "bottom 30%",
					toggleActions: "play none none reverse",
					scrub: true
				},
				y: -20
			});
			
			if(this.playAnimation) {
				
				gsap.timeline({
							scrollTrigger: {
								trigger: this.$refs.container,
								start: "top 70%",
								end: "bottom 30%",
								toggleActions: "play none none reverse",
							},
						})
						.from(this.$refs.quote, {autoAlpha: 0, x: -50, duration: 0.5})
						.from(this.$refs.gold, {scaleY: 0,  duration: 1, ease: "power2.out", delay: 0.25}, "-=0.5")
						.from(this.$refs.author, {autoAlpha: 0, duration: 0.5, delay: 0.5})
			}
			
		}
	}
</script>

<style lang="scss">
	
	.mobile-pad {
		@media screen and (max-width: $tablet) {
			padding: $mobile-pad $mobile-pad 0 $mobile-pad;
		}
	}
	
	.pull-quote-partial {
		.author {
			margin-bottom: 0;
		}
	}
	
  .pull-quote-container {
    padding-right: 30px;
    position: relative;
		margin-bottom: 15px;
		
		@media screen and (max-width: $tablet) {
			padding-right: 0;
		}
  }
  .gold-line-left-animate {
    top: 3px;
    left: 0;
    width: 4px;
    background-color: $gold;
    height: 98%;
    position: absolute;
  }
	.pull-quote {
		padding-left:40px;
    margin-bottom: 0;
    @media screen and (max-width: $tablet) {
      padding-left: 30px;
    }
		&.fancy {
			font-family: $domaine;
			font-weight: $semi;
			font-size: 3rem;
			line-height: 1.6;
      @media screen and (max-width: $tablet) {
        font-size: 2.5rem;
      }
		}
	}
</style>