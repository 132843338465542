<template>
	<div class="Y-2019">

		<MultiSplitSlantHalf
			top-theme="dark"
			bottom-theme="light"
			slant-direction="right"
			slant-half="top"
		>

			<template v-slot:top>

				<YearPartial ref="year" :year="2019" text-align="center" />

				<TwoColumn :pad-col-left="true" :mobile-reorder="true">
					<template v-slot:left>
						<HeadlinePartial ref="headline" :headline="$t('2019.1.headline')" />
						<BodyPartial ref="body" :body="$t('2019.1.body')" />
					</template>
					<template v-slot:right>
						<PullQuotePartial ref="quote" :quote="$t('2019.1.pull-quote')" :author="$t('2019.1.author')" />
					</template>
				</TwoColumn>

				<SingleColumn>
					<ImagePartial :play-animation="false" ref="image" image="2019_ceosuccession.jpg" />
				</SingleColumn>

			</template>

			<template v-slot:bottom>

				<TwoColumn :pad-col-left="true" :mobile-reorder="true">
					<template v-slot:left>
						<HeadlinePartial  :play-animation="true" :headline="$t('2019.2.headline')" />
						<BodyPartial :play-animation="true" :body="$t('2019.2.body')" />
					</template>
					<template v-slot:right>
						<ImagePartial image="2019_esgreport.jpg" />
					</template>
				</TwoColumn>

				<TwoColumn :pad-col-right="true" class-prop="third-column">
					<template v-slot:left>
						<Vimeo :vimeo-code="$t('2019.3.video')" />
					</template>
					<template v-slot:right>
						<HeadlinePartial  :play-animation="true" :headline="$t('2019.3.headline')" />
						<BodyPartial :play-animation="true" :body="$t('2019.3.body')" />
					</template>
				</TwoColumn>

				<TwoColumn :pad-col-left="true" class-prop="fifth-column" >
					<template v-slot:left>
						<HeadlinePartial  :play-animation="true" :headline="$t('2019.4.headline')" />
						<BodyPartial :play-animation="true" :body="$t('2019.4.body')" />
					</template>
					<template v-slot:right>
						<ImagePartial image="2019_vanoffice.jpg" />
					</template>
				</TwoColumn>

			</template>

		</MultiSplitSlantHalf>

	</div>
</template>

<script>
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import YearPartial from "@/components/partials/YearPartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import ImagePartial from "@/components/partials/ImagePartial";
  import PullQuotePartial from "@/components/partials/PullQuotePartial";
	import SingleColumn from "@/components/sub-layouts/SingleColumn";
	import {bgcolor} from "@/mixins/bgcolor";
	import MultiSplitSlantHalf from "@/components/layouts/MultiSplitSlantHalf";
	import Vimeo from "@/components/partials/Vimeo";

	export default {
		name: "Y-2019",
		mixins: [bgcolor],
		data() {
			return {
				bgColor: 'light'
			}
		},
		components: {
			Vimeo,
			MultiSplitSlantHalf,
			SingleColumn,
			PullQuotePartial, ImagePartial, BodyPartial, HeadlinePartial, YearPartial, TwoColumn}
	}
</script>

<style lang="scss">
	.Y-2019 {
		.top-slant-split {
			@media screen and (max-width: $tablet) {
				padding-bottom: 100px;
			}
		}
		.bottom-slant-split {
			padding-top: 250px;
			@media screen and (max-width: $tablet) {
				padding-top: 150px;
			}
		}
		.single-column {
			margin-bottom: -250px;
		}
		.third-column {
			@media screen and (max-width: $tablet) {
				.vimeo {
					margin-bottom: $mobile-pad;
					padding: 0 $mobile-pad;
				}
			}

		}
		.fifth-column {
			@media screen and (max-width: $tablet) {
				.image-container {
					padding: 0 $mobile-pad;
				}
			}
		}
		.two-column {
			margin-top: 100px;
			@media screen and (max-width: $tablet) {
				margin-top: $mobile-pad;
        .right-column {
          padding: 0 $mobile-pad;
        }
			}
		}
	}
</style>
