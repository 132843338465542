<template>
	<div class="Y-2001">
		<FullImage
			image="2001_usingress.jpg"
			theme="dark"
			bg-color="#011e2c"
			vertical-align="top"
      max-width="650px"
			horizontal-align="left">
			
			<YearPartial ref="year" :year="2001" />
			<HeadlinePartial ref="headline" :headline="$t('2001.headline')" />
			<BodyPartial ref="body" :body="$t('2001.body')" />
			
		</FullImage>
	</div>
</template>

<script>
	import FullImage from "@/components/layouts/FullImage";
	import YearPartial from "@/components/partials/YearPartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	export default {
		name: "Y-2001",
		mixins: [bgcolor],
		
		components: {BodyPartial, HeadlinePartial, YearPartial, FullImage}
	}
</script>

<style lang="scss">
  .Y-2001 {
    .full-bleed-image {
      min-height: 140vh;
      background-position: bottom right;
      background-size: cover;
      @media screen and (max-width: $tablet) {
        min-height: 100vh;
        background-position: bottom right;
        background-size: cover;
        padding-bottom: 110px;
        padding-top: 0;
      }
    }
  }
</style>