import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import i18n from './i18n'
import 'es6-promise/auto'
import storeData from "./store/index";
import Vue2TouchEvents from 'vue2-touch-events'

Vue.config.productionTip = false

Vue.use(Vuex);
Vue.use(Vue2TouchEvents);

const store = new Vuex.Store(storeData);

new Vue({
  i18n,
  render: h => h(App),
  store
}).$mount('#app')
