<template>
	<div class="Y-1997">
		<FullImage
			image="1997_SteveB.jpg"
			theme="dark"
			vertical-align="top"
			max-width="600px"
			horizontal-align="left">
			<YearPartial ref="year" :year="1997" />
			<HeadlinePartial ref="headline" :headline="$t('1997.headline')" />
			<BodyPartial ref="body" :body="$t('1997.body')" />
		</FullImage>
	</div>
</template>

<script>
	import FullImage from "@/components/layouts/FullImage";
	import YearPartial from "@/components/partials/YearPartial";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	export default {
		name: "Y-1997",
		mixins: [bgcolor],
		
		components: {BodyPartial, HeadlinePartial, YearPartial, FullImage}
	}
</script>

<style lang="scss">
  .Y-1997 {
    .full-bleed-image {
			min-height: 900px;
			.bg-image img {
				object-position: bottom right;
			}
			@media screen and (max-width: $tablet) {
				.content {
					padding-bottom: 300px;
				}
			}
    }
  }
</style>