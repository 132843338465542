<template>
	<div :class="['year-container', `align-${textAlign}`, pad ? 'pad' : '']">
    <div ref="year" class="inner-year-container">
      <h2 ref="splitYear" v-html="splitYear"></h2>
      <div ref="gold" class="gold-line"></div>
    </div>
	</div>
</template>

<script>
	import { animations } from "@/mixins/animation"
  import gsap from "gsap";

	export default {
		name: "YearPartial",
		mixins: [animations],
		props: {
			year: {
				type: Number,
				default: 1990
			},
			pad: {
				type: Boolean,
				default: false
			},
			textAlign: {
				type: String,
				default: 'left'
			},
			sectionTheme: {
				type: String,
				default: 'dark'
			}
		},
		computed: {
			splitYear() {
				// this function takes the year, and wraps each digit in a span so we can animate the numbers individually
				return this.year.toString().split("").map((item) => `<span>${item}</span>`).join('');
			}
		},
		mounted() {
			let _tl = gsap.timeline({
						scrollTrigger: {
							trigger: this.$refs.year,
							start: "top 70%",
							end: "70% top",
							toggleActions: "play none none reverse"
						},
					})
					.from(this.$refs.gold, {scaleX: 0,  duration: 0.5, ease: "power2.out"}, "-=1")
			
			Array.from(this.$refs.splitYear.children).forEach((item) => {
				_tl.add(gsap.from(item, {y: 100, duration: 1, ease: "power4.out"}), "-=0.9");
			});
			
		}
	}
</script>

<style lang="scss">
	.year-container {
    margin-bottom: $desktop-pad;
    width: auto;
    @media screen and (max-width: $tablet) {
      margin-bottom: 50px;
    }
		span {
			display: inline-block;
		}
    .inner-year-container {
      display: inline-block;
      width: auto;
      transform-origin: top left;
			overflow: hidden;
    }
    &.align-center {
      text-align: center;
      .inner-year-container {
        transform-origin: center center;
      }
    }

    h2 {
      font-family: $ivy;
      font-weight: $extra-bold;
      line-height: 1;
      font-size: 10rem;
      margin-bottom: 10px;
		}
    .gold-line {
      width: 100%;
      height: 2px;
      background-color: $gold;
      display: block;
    }

		&.pad {
			padding: 0 $desktop-pad;
			@media screen and (max-width: $tablet) {
				padding: 0 $mobile-pad;
			}
		}
	}
</style>