<template>
  <section ref="outro" id="Outro">
    <div class="inner-content">
      <ImagePartial :parallax="false" image="bam-group-photo.jpg" />

      <div ref="trigger" class="outro-intro">
        <div ref="logo" class="logo">
          <a :href="$t('outro.url')" target="_blank"
            ><img
              :src="
                require('@/assets/images/Burgundy_LOGOS-' +
                  $root.$i18n.locale +
                  '.svg')
              "
              alt="Burgundy Logo"
          /></a>
        </div>

        <div class="headline-container">
          <h3 ref="headline">{{ $t("outro.headline") }}</h3>
          <div ref="goldbar" class="gold-line-bottom"></div>
        </div>

        <p ref="body" v-html="$t('outro.body')"></p>
      </div>

      <div class="content">
        <h3 ref="subhead">{{ $t("outro.subhead") }}</h3>

        <div ref="social" class="social">
          <a
            :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/linkedin.svg')"
              alt="LinkedIn Share"
            />
          </a>
          <a
            :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/facebook.svg')"
              alt="Facebook Share"
            />
          </a>
          <a
            :href="`https://twitter.com/intent/tweet?text=${url}`"
            target="_blank"
          >
            <img
              :src="require('@/assets/images/twitter.svg')"
              alt="Twitter Share"
            />
          </a>
        </div>
      </div>

      <a class="scroll-up" @click="backToTop">
        <p ref="cta" class="scroll-cta">{{ $t("outro.cta") }}</p>
        <div class="scroll-image-container">
          <img
            ref="arrow"
            class="scroll-arrow back-to-beginning"
            :src="require('@/assets/images/modern-arrow.svg')"
            alt=""
          />
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import ImagePartial from "@/components/partials/ImagePartial";

export default {
  name: "Outro",
  components: { ImagePartial },
  computed: {
    url() {
      return window.location.href;
    },
  },
  mounted() {
    this.outroAnimation();
  },
  methods: {
    backToTop(e) {
      e.preventDefault();
      window.scrollTo(0, 0);
    },
    outroAnimation() {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.trigger,
          start: "top 70%",
          end: "bottom top",
          toggleActions: "play none none reverse",
          onEnter: () => {
            this.$store.commit("timelineActive", false);
            this.$store.commit("activeYear", 2020);
          },
        },
      });

      tl.add(
        gsap
          .timeline()
          .from(this.$refs.logo, { autoAlpha: 0, duration: 0.5 })
          .from(this.$refs.headline, { autoAlpha: 0, duration: 0.5 })
          .from(this.$refs.goldbar, { scaleX: 0, duration: 0.5 }, "-=0.5")
          .from(this.$refs.body, { autoAlpha: 0, x: 40, duration: 0.5 })
          .from(this.$refs.subhead, { autoAlpha: 0, duration: 0.5 })
      );

      Array.from(this.$refs.social.children).forEach((icon) => {
        tl.add(gsap.from(icon, { autoAlpha: 0, scale: 1.2, duration: 0.15 }));
      });

      tl.add(
        gsap
          .timeline()
          .from(this.$refs.cta, { autoAlpha: 0, duration: 0.5 })
          .from(this.$refs.arrow, { autoAlpha: 0, y: 20, duration: 0.5 })
      );
    },
  },
};
</script>

<style lang="scss">
#Outro {
  text-align: center;
  color: white;
  background-color: $deep-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: $mobile) {
    padding-bottom: 90px;
  }
  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    @media screen and (max-width: $mobile) {
      margin-bottom: 30px;
    }
    a {
      display: block;
      overflow: auto;
      margin: 0 10px;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  .logo {
    padding: 100px 20px 80px 20px;
    text-align: center;
    @media screen and (max-width: $mobile) {
      padding: 50px 20px;
    }
    img {
      width: 300px;
    }
  }
  .content {
    max-width: 550px;
  }
  .inner-content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background: linear-gradient(
      to bottom,
      rgba($deep-blue, 0.95),
      rgba($deep-blue, 0.75)
    );
    width: 100%;
    padding: 0;
  }
  h1 {
    width: fit-content;
    margin-bottom: 50px;
  }
  .outro-intro {
    max-width: 900px;
    text-align: left;
    margin-bottom: 40px;
    padding: 0 30px;

    .gold-line-bottom {
      width: 100%;
      height: 3px;
      background-color: $gold;
      display: block;
      margin-bottom: 40px;
    }
    .headline-container {
      width: fit-content;
    }
    h3 {
      font-family: $domaine;
      text-transform: none;
      font-size: 2.4rem;
      margin-bottom: 10px;
    }
    @media screen and (max-width: $mobile) {
      margin-bottom: 30px;
    }
  }
  h3 {
    margin-bottom: 30px;
  }
  .back-to-beginning {
    transform: none;
  }
  .scroll-up {
    background: none;
    border: none;
    margin: 0;
    padding: 0 40px;
    border-radius: 0;
    color: white;
    cursor: pointer;
    p {
      margin-bottom: 10px;
    }
    @media screen and (max-width: $mobile) {
      .scroll-cta {
        margin-bottom: 15px;
      }
    }
  }
}
</style>
