<template>
	<div class="Y-1991">
		
		<Basic theme="dark">
			
			<YearPartial text-align="center" ref="year" :year="1991" :pad="true" />
			
			<TwoColumn :pad-col-left="true" :pad-col-right="false">
				
				<template v-slot:left>
					<HeadlinePartial ref="headline" :headline="$t('1991.top.headline')" />
					<BodyPartial ref="body" :body="$t('1991.top.body')" />
				</template>
				
				<template v-slot:right>
					<ImagePartial image="1991_David.jpg" />
				</template>
				
			</TwoColumn>
			
		</Basic>
		
	</div>
</template>

<script>
	import YearPartial from "@/components/partials/YearPartial";
	import TwoColumn from "@/components/sub-layouts/TwoColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	import ImagePartial from "@/components/partials/ImagePartial";
	import Basic from "@/components/layouts/Basic";

	export default {
		name: "Y-1991",
		mixins: [bgcolor],
		components: {
			Basic,
			ImagePartial,
			BodyPartial, HeadlinePartial, TwoColumn, YearPartial}
	}
</script>

<style lang="scss">

	.Y-1991 {
		.top-slant-split {
			padding-bottom: 150px;
      @media screen and (max-width: $tablet) {
        padding-bottom: 140px;
      }
		}
		.bottom-slant-split {
			.two-column {
				padding-top: 50px;
				.left-column {
					@media screen and (min-width: $tablet) {
						padding-right: 0;
					}
					@media screen and (max-width: $tablet) {
						.vimeo {
							margin-bottom: $mobile-pad;
						}
					}
				}
			}
		}
	}

</style>