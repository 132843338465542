<template>
	<div ref="imageContainer" :class="['image-container', fullHeight ? 'full-height' : '']">
		<img ref="image" v-if="image" :src="require(`@/assets/images/${image}`)" :alt="alt" />
	</div>
</template>

<script>
	import gsap from "gsap";

  export default {
		name: "ImagePartial",
		props: {
			image: {
				type: String,
				default: ''
			},
			alt: {
				type: String,
				default: ''
			},
			fullHeight: {
				type: Boolean,
				default: false
			},
			playAnimation: {
				type: Boolean,
				default: true
			},
			parallax: {
				type: Boolean,
				default: true
			},
			scale: {
				type: Number,
				default: 1
			}
		},
    mounted() {

			if(this.playAnimation) {
				
				gsap.set(this.$refs.imageContainer, {autoAlpha: 0});

				gsap.to( this.$refs.imageContainer,{
					scrollTrigger: {
						trigger: this.$refs.imageContainer,
						start: "top 80%",
						end: "bottom 50%",
						toggleActions: "play none none reverse",
					},
					autoAlpha: 1,
					duration: 0.5,
					delay: 0.25
				});
				
			}
			
			if(this.parallax) {
				
				let scale = this.fullHeight ? 1.15 : this.scale;
				
				gsap.set(this.$refs.image, {y: 30, scale: scale});
				
				gsap.to( this.$refs.image,{
					scrollTrigger: {
						trigger: this.$refs.imageContainer,
						start: "top 90%",
						end: "bottom 10%",
						toggleActions: "play none none reverse",
						scrub: true
					},
					y: -15,
				});
				
			}
    
    }
	}
</script>

<style lang="scss">
	.image-container {
		overflow: hidden;
		img {
			max-width: 100%;
			width: 100%;
      display: block;
		}
		&.full-height {
			min-height: 100vh;
			height: 100%;
			overflow: hidden;
			img {
				width: 100%;
				min-height: 100vh;
				height: 100%;
				object-fit: cover;
			}
      @media screen and (max-width: $tablet) {
        min-height: auto;
        img {
          min-height: auto;
          height: auto;
        }
      }
		}
	}
</style>