<template>
	<div class="french-toggle">
		<a :href="url">{{ $t('language') }}</a>
	</div>
</template>

<script>
export default {
	name: "FrenchToggle",
	computed: {
		url() {
			return process.env.VUE_APP_SWITCH_LOCALE_URL;
		}
	}
}
</script>

<style lang="scss">
	.french-toggle {
		position: absolute;
		top: 0;
		right: 0;
		a {
			font-size: 16px;
			display: block;
			text-decoration: none;
			background-color: $deep-blue;
			color: white;
			border-radius: 0;
			padding: 10px 20px;
			font-family: $gotham;
			border: 1px solid lighten($deep-blue, 5%);
			cursor: pointer;
		}
	}
</style>