<template>
	<section :class="['multi-split-slant', `${slantHalf}-slant-half`, `${slantDirection}-slant-direction`]" :data-year="year">
		
		<div ref="top" :class="['top-slant-split', topTheme]">
			
			<div class="inner-content">
				<slot name="top"></slot>
			</div>
			
		</div>
		
		<div ref="bottom" :class="['bottom-slant-split', bottomTheme]">
			
			<div class="inner-content">
				<slot name="bottom"></slot>
			</div>
			
		</div>
		
	</section>
</template>

<script>

  import gsap from 'gsap';

	export default {
		name: "MultiSplitSlantHalf",
		props: {
			year: {
				type: Number,
				default: null
			},
			slantDirection: {
				type: String,
				default: 'right'
			},
			slantHalf: {
				type: String,
				default: 'top'
			},
			topTheme: {
				type: String,
				default: "dark"
			},
			bottomTheme: {
				type: String,
				default: "light"
			}
		},
    mounted() {
			
			let el = (this.slantHalf === 'top') ? this.$refs.top : this.$refs.bottom;
			let start = (this.slantHalf === 'top') ? "bottom 95%" : "top 95%";
			
      gsap.to( el,{
        scrollTrigger: {
          trigger: el,
          start: start,
          end: "bottom top",
          toggleActions: "play none none reverse",
					toggleClass: {targets: el, className: "active"}
				},
      });
      
    }
	}
</script>

<style lang="scss">
		.multi-split-slant {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			.text-vimeo {
				padding: 30px $desktop-pad;
				@media screen and (max-width: $tablet) {
					padding: 15px $mobile-pad;
				}
			}
			.light {
				background-color: $cream;
				color: $deep-blue;
				&:after {
					background-color: $cream;
				}
			}
			.dark {
				background-color: $deep-blue;
				color: white;
				&:after {
					background-color: $deep-blue;
				}
			}
		}
		
		.top-slant-half {
			.bottom-slant-split {
				z-index: 0;
			}
			.top-slant-split {
				z-index: 1;
				&:after {
					content: " ";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					z-index: -1;
					transition: all .5s cubic-bezier(0.5, 1, 0.89, 1);
					transform: skew(0);
				}
			}
			&.right-slant-direction {
				.top-slant-split {
					&:after {
						transform-origin: bottom right;
					}
					&.active {
						&:after {
							transform: skewY(-6deg);
						}
					}
				}

			}
			&.left-slant-direction {
				.top-slant-split {
					&:after {
						transform-origin: bottom left;
					}
					&.active {
						&:after {
							transform: skewY(6deg);
						}
					}
				}
			}
		}
		
		.bottom-slant-half {
			.top-slant-split {
				z-index: 0;
			}
			.bottom-slant-split {
				z-index: 1;
				&:after {
					content: " ";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: -1;
					transition: all .5s cubic-bezier(0.5, 1, 0.89, 1);
					transform: skewY(0);
				}
			}
			&.right-slant-direction {
				.bottom-slant-split {
					&:after {
						transform-origin: top left;
					}
					&.active {
						&:after {
							transform: skewY(-6deg);
						}
					}
				}

			}
			&.left-slant-direction {
				.bottom-slant-split {
					&:after {
						transform-origin: top right;
					}
					&.active {
						&:after {
							transform: skewY(6deg);
						}
					}
				}
			}
		}
		
		.top-slant-split {
			color: white;
			position: relative;
			min-height: 50vh;
			width: 100%;
			padding-top: $desktop-pad;
			@media screen and (max-width: $tablet) {
				padding-top: $mobile-pad;
			}
			.text-quote {
				margin-bottom: -20px;
			}
		}
		.bottom-slant-split {
			position: relative;
			min-height: 50vh;
			width: 100%;
		}
		
</style>