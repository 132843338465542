<template>
	<div class="Y-1990">
		
		<Basic theme="light">
			
			<SingleColumn max-width="600px">
				<YearPartial :section-theme="`light`" ref="year" :year="1990" text-align="center" />
				<HeadlinePartial ref="headline" :headline="$t('1990.headline')" />
				<BodyPartial ref="body" :body="$t('1990.body')" />
			</SingleColumn>
			
		</Basic>
		
	</div>
</template>

<script>

	import Basic from "@/components/layouts/Basic";
	import SingleColumn from "@/components/sub-layouts/SingleColumn";
	import HeadlinePartial from "@/components/partials/HeadlinePartial";
	import BodyPartial from "@/components/partials/BodyPartial";
	import YearPartial from "@/components/partials/YearPartial";
	import {bgcolor} from "@/mixins/bgcolor";
	
	export default {
		name: "Y-1990",
		mixins: [bgcolor],
		components: {
			YearPartial,
			BodyPartial,
			HeadlinePartial,
			SingleColumn,
			Basic
		},
		data() {
			return {
				bgColor: 'light'
			}
		}
  }
</script>