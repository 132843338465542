<template>
	<div class="nav-container">
    <transition name="fade">
      <div v-show="timelineActive" class="nav-inner-container">

        <button
            class="arrow-prev"
            @click="incrementYear(-1)"
            v-if="$store.getters.getActiveYearIndex !== 0"
            aria-label="Previous Year"
        >
          <img :src='require("@/assets/images/modern-arrow.svg")' alt="" />
        </button>

        <nav>
          <ul v-touch:swipe.left="() => { swipeLeft(1) }"
							v-touch:swipe.right="() => { swipeRight(-1) }"
							ref="carousel"
							:style="{transform: carouselTransform}">
            <li v-for="year in years" :key="year">
              <button :style="{fontWeight: year === activeYear ? 'bold' : 'normal'}" @click="selectYear(year)" :aria-label="`Go to year ${year}`">{{ year }}</button>
            </li>
          </ul>
        </nav>

        <button
            class="arrow-next"
            @click="incrementYear(1)"
            v-if="$store.getters.getActiveYearIndex !== (years.length - 1)"
            aria-label="Next Year"
        >
          <img :src='require("@/assets/images/modern-arrow.svg")' alt="" />
        </button>

      </div>
    </transition>
	</div>
</template>

<script>
	import { mapState } from "vuex"
	
	export default {
		name: "Navigation",
		data() {
			return {
				cellSize: 50,
				cellCount: 40,
				theta: 360 / 40,
				mobileWidth: 550,
				windowWidth: window.innerWidth
			}
		},
		computed: {
			...mapState(['years', 'activeYear', 'timelineActive']),
			carouselTransform() {
				let radius = Math.round( ( this.cellSize / 2) / Math.tan( Math.PI / this.cellCount ) );
				let angle = this.theta * this.$store.getters.getActiveYearIndex;
				
				if(this.windowWidth < this.mobileWidth) {
					angle = this.theta * this.$store.getters.getActiveYearIndex * -1;
				}
				
				let rotate = this.windowWidth < this.mobileWidth ? `rotateY(${angle}deg) rotateX(0deg)` : `rotateX(${angle}deg) rotateY(0deg)`;
				return `translateZ(${-radius}px) ${rotate}`;
			}
		},
		mounted() {
			window.addEventListener('resize', () => {
				this.windowWidth = window.innerWidth;
			})
		},
		methods: {
			incrementYear(increment) {
				let index = (this.$store.getters.getActiveYearIndex + increment) % this.years.length;
				let year = this.years[index];
				this.$store.commit('activeYear', year);
				this.jumpToYear(year, increment);
			},
			swipeRight() {
				this.incrementYear(-1);
			},
			swipeLeft() {
				this.incrementYear(1);
			},
			selectYear(year) {
				let direction = this.$store.state.activeYear - year;
				this.$store.commit('activeYear', year);
				this.jumpToYear(year, -direction);
			},
			jumpToYear(year, direction) {
				let element = this.$parent.$refs[year][0].$el;
				let yOffset = -59;
				
				if(direction < 0) {
					yOffset = 0;
				}
				
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				
				window.scrollTo({top: y});
				
			},
		}
	}
</script>

<style lang="scss">

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

	.nav-container {
		position: fixed;
		height: 100vh;
		width: 100px;
		left: 0;
		top: 0;
		border-right: 1px solid $gold;
		background-color: $deep-blue;
		z-index: 100000;
		@media screen and (max-width: $mobile) {
			top: auto;
			bottom: 0;
			width: 100vw;
			height: 50px;
			border-right: 0;
			border-top: 1px solid $gold;
			z-index: 3;
		}
		.nav-inner-container {
			position: relative;
			height: 100vh;
			width: 100%;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@media screen and (max-width: $mobile) {
				height: 50px;
				width: 100vw;
				flex-direction: row;
			}
			&:after {
				position: absolute;
				content: " ";
				width: 100%;
				height: 100%;
				z-index: 1;
				background: linear-gradient(to bottom, $deep-blue 15%, rgba($deep-blue, 0), rgba($deep-blue, 0), $deep-blue 85%);
				pointer-events: none;
				@media screen and (max-width: $mobile) {
					background: linear-gradient(to right, $deep-blue 25%, rgba($deep-blue, 0), rgba($deep-blue, 0), $deep-blue 75%);
				}
			}
			nav {
				position: relative;
				border-top: 1px solid $gold;
				border-bottom: 1px solid $gold;
				width: 100%;
				height: 50px;
				margin: 0 auto;
				perspective: 1000px;
				@media screen and (max-width: $mobile) {
					width: 100px;
					height: 50px;
					border: none;
					border-right: 1px solid $gold;
					border-left: 1px solid $gold;
				}
				ul {
					list-style-type: none;
					width: 100%;
					height: 100%;
					position: absolute;
					transform: translateZ(-318px);
					transform-style: preserve-3d;
					transition: transform 0.5s;
					@media screen and (max-width: $mobile) {
						transform: translateZ(-500px);
					}
					li {
						position: absolute;
						width: 100%;
						height: 50px;
						left: 0;
						top: 0;
						backface-visibility: hidden;
						line-height: 50px;
						font-weight: bold;
						color: $gold;
						text-align: center;
						transition: transform 0.5s, opacity 0.5s;
						@media screen and (max-width: $mobile) {
							width: 100px;
						}
						button {
							font-size: 18px;
							border-radius: 0;
							background: none;
							border: 0;
							color: $gold;
							font-family: $gotham;
							cursor: pointer;
							pointer-events: auto;
							@media screen and (max-width: $mobile) {
								font-size: 16px;
								width: 70%;
								pointer-events: none;
							}
						}
						@for $i from 1 through 22 {
							&:nth-child(#{$i}) {
								transform: rotateX(calc(#{$i - 1} * -9deg)) translateZ(318px);
							}
						}
						@media screen and (max-width: $mobile) {
							@for $i from 1 through 22 {
								&:nth-child(#{$i}) {
									transform: rotateY(calc(#{$i - 1} * 9deg)) translateZ(500px);
								}
							}
						}
					}
				}
			}
			.arrow-next, .arrow-prev {
				position: absolute;
				margin: 0 auto;
				left: 0;
				right: 0;
				z-index: 3;
				background: transparent;
				border: 0;
				cursor: pointer;
			}
			.arrow-prev {
				top: 10px;
				@media screen and (max-width: $mobile) {
					top: auto;
					right: auto;
					left: 25px;
					bottom: auto;
					margin: 0;
					width: 60px;
					height: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					
					img {
						height: 60px;
						transform: rotate(-90deg);
					}
				}
			}
			.arrow-next {
				bottom: 10px;
				img {
					transform: rotate(180deg);
				}
				@media screen and (max-width: $mobile) {
					top: auto;
					left: auto;
					right: 25px;
					bottom: auto;
					margin: 0;
					width: 60px;
					height: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						height: 60px;
						transform: rotate(90deg);
					}
				}
			}
		}
	}
</style>